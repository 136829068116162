<!--课程协议详情-->
<template>
    <div class="app-page-2">
        <div class="container">
            <Banner></Banner>
            <div class="mineContainer">
                <LeftNav></LeftNav>

                <div class="protocolContain">
                    <div class="title">
                        <h3>{{protocolName}}</h3>
                    </div>
                    <div class="protocol_list">
                        <ul>
                            <li class="li" v-for="(item,index) in protocolImgs" :key='index'>
                                <img :src="item">
                            </li>
                            
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Banner from '@/components/mine/banner'
import LeftNav from '@/components/mine/leftNav'
import { getProtocolList} from '@/api/api'
export default {
    name:'course',
    components:{
        Banner,
        LeftNav
    },  
    
    middleware: 'accountVerification',
    data(){
      return{
        page: 1,
        limit: 1000,
        protocolList:[],
        protocolName:null,
        protocolImgs:[],
        type: 2,
      }
    },
    mounted(){
      this.id = this.$route.query.id
      this.getProtocolList()
    },
    methods:{
      // 获取订单
      async getProtocolList() {
        const params = {
            page: this.page,
            limit: this.limit
        }
        let response = await getProtocolList(params)
        if(+response.returnCode !== 10001) {
            return false
        }
        this.protocolList = response.data
        let protocolList = response.data
        for(const item of protocolList) {
            if(item.id === this.id) {
                this.protocolName = item.protocolName
                this.protocolImgs = item.protocolImg.split(',')
            }
        }
      },

    },
}
</script>
<style lang="less" scoped >
.container{
  padding-top:0px;
}
.mineContainer{
    width:1200px;
    overflow: hidden;
    background:#fff;
    margin:0 auto 30px auto;
    border: 1px solid #DBDBDB;
    display: flex;
}
.protocolContain{
    width:985px;
    padding:0 30px;
    overflow: hidden;
    background:#fff;
    border-left:1px solid #E8E8E8;
    min-height: 375px;
    float:left;
    .title h3{
        font-size: 22px;
        color:#333333;
        text-align: left;
        font-weight: normal;
        line-height: 75px;
        height: 75px;
    }
    .protocol_list{
        ul{
            margin:0;
            padding:0;
            .li{
                width:100%;
                height: auto;
                img{
                    width:100%;
                }
            }
                
        } 
    }
    
    
    
}
</style>
